import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactImageMagnify from "react-image-magnify";
import moment from "moment";
import "moment/locale/nl";
import {
  FaExternalLinkAlt,
  FaRegEnvelope,
  FaRegClipboard,
  FaPencilAlt,
  FaTimes,
} from "react-icons/fa";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

import { AuthContext } from "../../context/main/auth-context";
import { downloadBlobFile } from "../../utils/download-blob-file";
import getShippingLabelPdf from "./get-shipping-label-pdf";

import flagNetherlands from "../../images/main/flag-netherlands.svg";
import flagBelgium from "../../images/main/flag-belgium.svg";
import postnlBolMailbox from "../../images/shipping/POSTNL-BOL-MAILBOX.png";
import postnlBolParcel from "../../images/shipping/POSTNL-BOL-PARCEL.png";
import postnlBolParcelPickup from "../../images/shipping/POSTNL-BOL-PARCEL-PICKUP.png";
import postnlBolParcelNight from "../../images/shipping/POSTNL-BOL-PARCEL-NIGHT.png";
import dhlBolMailbox from "../../images/shipping/DHL-BOL-MAILBOX.png";
import dhlBolParcel from "../../images/shipping/DHL-BOL-PARCEL.png";
import dhlBolParcelPickup from "../../images/shipping/DHL-BOL-PARCEL-PICKUP.png";
import dhlBolParcelNight from "../../images/shipping/DHL-BOL-PARCEL-NIGHT.png";

import truckIcon from "../../images/main/delivery-truck.svg";
import deadline from "../../images/main/deadline.png";
import siren from "../../images/main/siren.svg";

const OrderListItemsBolShipStep3 = (props) => {
  const [showAlertText, setShowAlertText] = useState(false);

  const animateAlertText = (text) => {
    setShowAlertText(text);
    setTimeout(() => setShowAlertText(false), 3000);
  };

  const countryCodeToName = (cc) => {
    if (cc === "NL") {
      return "Nederland";
    } else if (cc === "BE") {
      return "België";
    } else {
      return cc;
    }
  };

  if (
    props &&
    props.order &&
    props.order.orderItems &&
    props.order.orderItems.length > 0
  ) {
    return (
      <div className="o-li-item o-li-item-ship-bol-step-3">
        <div className="o-li-item-ship-bol-step-3-col1">
          <div className="o-li-item-index">{props.index + 1}</div>
          <div className="o-li-item-country-flag">
            {(() => {
              if (props.order.shipmentDetails.countryCode === "NL") {
                return <img src={flagNetherlands} alt="NL" />;
              } else if (props.order.shipmentDetails.countryCode === "BE") {
                return <img src={flagBelgium} alt="BE" />;
              } else {
                return props.order.shipmentDetails.countryCode;
              }
            })()}
          </div>
          <div className="o-li-item-orderNumber">
            <span>#</span>
            {props.order.orderId}
          </div>
        </div>
        <div className="o-li-item-address">
          <CopyToClipboard
            text={
              (props.order.shipmentDetails.company
                ? props.order.shipmentDetails.company + "\n"
                : "") +
              props.order.shipmentDetails.firstName +
              " " +
              props.order.shipmentDetails.surname +
              "\n" +
              props.order.shipmentDetails.streetName +
              " " +
              (props.order.shipmentDetails.houseNumber +
                (props.order.shipmentDetails.houseNumberExtension
                  ? " " + props.order.shipmentDetails.houseNumberExtension
                  : "") +
                "\n" +
                (props.order.shipmentDetails.zipCode +
                  " " +
                  props.order.shipmentDetails.city) +
                "\n" +
                countryCodeToName(props.order.shipmentDetails.countryCode))
            }
            onCopy={() => {
              animateAlertText("Gekopieerd");
            }}
          >
            <div>
              <span
                className={
                  props.order.shipmentDetails.company &&
                  "o-li-item-address-name"
                }
              >
                {props.order.shipmentDetails.company
                  ? props.order.shipmentDetails.company
                  : ""}
                {props.order.shipmentDetails.company && <br />}
              </span>
              <span
                className={
                  !props.order.shipmentDetails.company &&
                  "o-li-item-address-name"
                }
              >
                {props.order.shipmentDetails.firstName + " "}
                {props.order.shipmentDetails.surname}{" "}
              </span>
              <br />
              {props.order.shipmentDetails.streetName +
                " " +
                props.order.shipmentDetails.houseNumber +
                (props.order.shipmentDetails.houseNumberExtension
                  ? " " + props.order.shipmentDetails.houseNumberExtension
                  : "")}
              <br />
              {props.order.shipmentDetails.zipCode +
                " " +
                props.order.shipmentDetails.city}
              <br />{" "}
              {countryCodeToName(props.order.shipmentDetails.countryCode)}
            </div>
          </CopyToClipboard>
        </div>
        <div className="o-li-item-content">
          {props.order.orderItems.map((orderItem, i) => {
            if (!orderItem.checked || orderItem.checked === false) {
              return false;
            }
            return (
              <OrderLayOutBolShipStep3
                order={props.order}
                handleChecked={props.handleChecked}
                handleShippingMethodChange={props.handleShippingMethodChange}
                orderItem={orderItem}
                orderItemIndex={i}
                key={i}
              />
            );
          })}
        </div>
        {showAlertText && <div className="snackbar">{showAlertText}</div>}
      </div>
    );
  } else {
    return false;
  }
};

const OrderLayOutBolShipStep3 = (props) => {
  return (
    <React.Fragment>
      <div className="o-li-item-data-step-3-product-title">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.bol.com/nl/s/?searchtext=${props.orderItem.product.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
        >
          <FaExternalLinkAlt />
        </a>
        {props.orderItem.product.title}
      </div>
      <div className="o-li-item-data-step-3">
        <div className="o-li-item-product-image-step-3 o-li-item-data-item-order-image">
          {props.orderItem.productData ? (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "productimage",
                  isFluidWidth: true,
                  src:
                    process.env.REACT_APP_ASSET_URL +
                    "/s3/" +
                    props.orderItem.productData.image,
                  width: 80,
                  height: 80,
                },
                largeImage: {
                  isFluidWidth: true,
                  src:
                    process.env.REACT_APP_ASSET_URL +
                    "/s3/" +
                    props.orderItem.productData.image,
                  width: 250,
                  height: 325,
                },
                hoverDelayInMs: 10,
                hoverOffDelayInMs: 10,
                enlargedImagePosition: "beside",
                enlargedImageContainerDimensions: {
                  width: "280%",
                  height: "280%",
                },
              }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="o-li-item-product-title-step-3"></div>
        <div className="o-li-item-data-item-step-3 o-li-item-product-quantity-step-3">
          <span>{props.orderItem.quantity}</span>
        </div>
        <div className="o-li-item-data-item-step-3 o-li-item-product-reference-step-3">
          <span>
            {props.orderItem.productData && props.orderItem.productData.title
              ? props.orderItem.productData.title
              : props.orderItem.offer.reference}{" "}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderListItemsBolShipStep3;
